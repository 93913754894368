import React, { Component, Fragment } from 'react';

import DrinkItem from './DrinkItem/DrinkItem';
import WineItems from '../WineItems/WineItems';
import SakeItems from '../SakeItems/SakeItems';

import classes from './DrinkItems.module.scss';

class DrinkItems extends Component {
    state = {
        showWines: false,
        showSake: false,
        selectedDrinkId: -1,
        wineItems: [],
        sakeItems: []
    }

    drinkItemClickHandler(item) {
        this.props.selected(item);

        this.setState({
            selectedDrinkId: item.id
        });

        if(item.tag === 'wines' && item.children.length > 0) {
            this.setState({
                showWines: true,
                wineItems: item.children
            });
        }

        if(item.tag === 'sake' && item.children.length > 0) {
            this.setState({
                showSake: true,
                sakeItems: item.children
            });
        }
    }

    wineItemsCloseHandler() {
        this.setState({
            showWines: false,
            wineItems: [],
            selectedDrinkId: -1
        });

        this.props.selected(this.props.navItem);
    }

    sakeItemsCloseHandler() {
        this.setState({
            showSake: false,
            sakeItems: [],
            selectedDrinkId: -1
        });

        this.props.selected(this.props.navItem);
    }

    closeDrinksHandler() {
        this.setState({
            selectedDrinkId: -1
        });

        this.props.close();
    }

    render() {
        const appLayout = localStorage.getItem('app_layout') || 'ementas_digitais';
        const appLayoutConfig = require('../../assets/layout-config.json');
        const currentLayoutConfig = appLayoutConfig[appLayout];

        const lang = localStorage.getItem('lang') || 'pt';
        const translations = require(`../../assets/translations/${lang}.json`);
        let attachedClasses = [classes[`${appLayout}_DrinkItems`], classes.Close].join(' ');

        if(this.props.show) {
            attachedClasses = [classes[`${appLayout}_DrinkItems`], classes.Open].join(' ');
        }
    
        return (
            <Fragment>
                <WineItems 
                    show={this.state.showWines}
                    items={this.state.wineItems}
                    close={this.wineItemsCloseHandler.bind(this)} 
                    selected={this.props.selected} />
                { appLayout !== 'ementas_digitais' ? //FIXME: REMOVE
                <SakeItems 
                    show={this.state.showSake}
                    items={this.state.sakeItems}
                    close={this.sakeItemsCloseHandler.bind(this)} 
                    selected={this.props.selected} /> : null }
                <div className={attachedClasses}>
                    <button 
                        className={classes[`${appLayout}_BackButton`]}
                        onClick={this.closeDrinksHandler.bind(this)}>
                            <span>
                            { currentLayoutConfig.backButtonArrow ? <span className={classes.arrow}>{'\uf177'}</span> : null }
                                <span className={classes.title}>{translations.menu.buttons.back}</span>
                            </span>
                        </button>
                    {
                        this.props.items.map(item => (
                            <DrinkItem
                                onClick={() => this.drinkItemClickHandler(item)}
                                isWineItem={item.tag === 'wines' && item.children.length > 0}
                                isSakeItem={item.tag === 'sake' && item.children.length > 0}
                                isSelected={item.id === this.state.selectedDrinkId}
                                key={item.id}
                                name={item.name}
                                tag={item.tag}/>
                        ))
                    }             
                </div>
            </Fragment>

        )
    }
}

export default DrinkItems;