import React, { Component } from 'react';

import SideDrawer from './components/SideDrawer/SideDrawer';
import FoodItems from './components/FoodItems/FoodItems';
import LandingPage from './components/LandingPage/LandingPage';
import OrderItems from './components/OrderItems/OrderItems';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionFilter: null,
      visibleItems: null
    };

    this.setSectionFilter = this.setSectionFilter.bind(this);
    this.setVisibleItems = this.setVisibleItems.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.sectionFilter !== this.state.sectionFilter;
  }

  setSectionFilter(section) {
    if(section && section !== this.state.sectionFilter) {
      this.setState({sectionFilter: section});
    }
  }

  setVisibleItems(itemType) {
    this.setState({
      visibleItems: itemType
    })
  }

  render() {
    return (
      <div>
        <LandingPage />
        <SideDrawer 
          selected={this.setSectionFilter} 
          setVisible={this.setVisibleItems} 
          visibleItems={this.state.visibleItems}/>
        {
          this.state.visibleItems === 'food' ? 
            <FoodItems filter={this.state.sectionFilter} changeFilter={this.setSectionFilter}/> : 
            this.state.visibleItems === 'order' ? <OrderItems /> : null
        }
      </div>
    );
  }
}

export default App;
