import React from 'react';
import classes from './LanguageItem.module.scss';

const LanguageItem = (props) => {
    const setLanguage = () => {
        localStorage.setItem('lang', props.lang);
        window.location.reload();
    };

    const appLayout = localStorage.getItem('app_layout') || 'ementas_digitais';
    const appLayoutConfig = require('../../../assets/layout-config.json');
    const currentLayoutConfig = appLayoutConfig[appLayout];
    const currentLang = localStorage.getItem('lang') || 'pt';
    const classNames = [classes[`${appLayout}_LanguageItem`], props.lang === currentLang ? 
        classes[`${appLayout}_LanguageItemSelected`] : null].join(' ');

    return (
        <div className={classNames}
            onClick={setLanguage}>
            { currentLayoutConfig.languagueItemsFlags ? 
            <img src={require(`../../../assets/images/flags/flag_${props.lang}.png`)}
                alt={props.lang} /> :
            <div>{props.lang.toUpperCase()}</div> 
            }
        </div>
    )
};

export default LanguageItem;