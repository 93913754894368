import React from 'react';

import classes from './NavigationItem.module.scss'

const NavigationItem = (props) => {
    const appLayout = localStorage.getItem('app_layout') || 'ementas_digitais';

    const icon = require(`../../../assets/images/icons/${props.icon || 'cutlery'}.png`);

    const buttonClasses = [classes.NavigationItem, 
        props.isDaily ? classes.covered : classes[props.icon] ? classes[props.icon] : classes.default].join(' ');

    const arrowClasses = [classes.arrow, 
        props.isDaily ? classes.covered : classes[props.icon] ? classes[props.icon] : classes.default].join(' ');

    const appLayoutConfig = require('../../../assets/layout-config.json');
    const currentLayoutConfig = appLayoutConfig[appLayout];
        
    return (
        <div className={classes[appLayout]}>
            <button className={buttonClasses} onClick={props.onClick}>
                { currentLayoutConfig.menuIcons ? <img alt={props.icon} src={icon} /> : null}
                <span className={[classes.title, 
                props.isSelected ? classes.selected : null].join(' ')}>
                    <span>{props.name} {currentLayoutConfig.childrenArrowsNavigation && props.hasChildren ? <span className={classes.arrow}>{'\uf178'}</span> : ''}</span>
                </span>
            </button>
            { currentLayoutConfig.arrows ? <span className={arrowClasses} /> : null }
         </div>
    )
};

export default NavigationItem;