import React, { Component } from 'react';

import classes from './FoodItemDetails.module.scss';

class FoodItemDetails extends Component {
    state = {
        showTooltip: false
    }

    tooltipClickHandler() {
        this.setState({
            showTooltip: !this.state.showTooltip
        });
    }

    render() {
        const appLayout = localStorage.getItem('app_layout') || 'ementas_digitais';
        const lang = localStorage.getItem('lang') || 'pt';
        const translations = require(`../../../../assets/translations/${lang}.json`);

        const tooltipContent = this.state.showTooltip ?
            <span className={classes.tooltip}>
                {
                    this.props.allergies.length > 0 ? this.props.allergies.map(allergy => {
                        const allergen = allergy.replace('allergies.', '');
                        return <div key={allergy}
                            className={classes.tooltipAllergen}
                            style={{ textAlign: 'left' }}>
                            <img alt={allergy}
                                src={require(`../../../../assets/images/allergies/${allergen}.png`)} />
                            <span>{translations.menu.allergies[allergen]}</span>
                        </div>
                    }) : <span>{translations.menu.allergies.no_allergens}</span>
                }
                {
                    Object.keys(this.props.nutrition).length <= 0 ? null :
                        <table className={classes.tooltipNutrition}>
                            <tbody>
                                <tr>
                                    <td className={[classes.nutritionType, classes.nutritionInfo].join(' ')}>
                                        {translations.menu.nutrition.info}
                                    </td>
                                    <td className={[classes.nutritionValue, classes.nutritionInfo].join(' ')}>
                                        100g
                                </td>
                                </tr>
                                {
                                    Object.keys(this.props.nutrition).map(objKey => {
                                        return <tr key={objKey}>
                                            <td className={classes.nutritionType}>{translations.menu.nutrition[objKey]}</td>
                                            <td className={classes.nutritionValue}>{this.props.nutrition[objKey]}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                }
            </span> : null;

        const allergiesContent = this.props.allergies.map(allergy => (
            <img key={allergy}
                alt={allergy}
                src={require(`../../../../assets/images/allergies/${allergy.replace('allergies.', '')}.png`)} />
        ));

        return (
            <div className={classes[appLayout]}>
                <div className={classes.FoodItemDetails}>
                    <div className={classes.foodMain}>
                        <div className={classes.foodName}>
                            {this.props.name}
                        </div>
                        <div className={classes.foodPrice}>
                            {
                                !this.props.price ? null :
                                    <span>{`${parseFloat(this.props.price).toFixed(2)}€`}</span>
                            }
                            {
                                Object.keys(this.props.otherPrices).length <= 0 ? null :
                                    Object.keys(this.props.otherPrices).map(objKey => {
                                        const price = this.props.otherPrices[objKey].toString().trim();

                                        return parseFloat(price) <= 0 ? null :
                                            <span key={objKey}>
                                                {`${parseFloat(price).toFixed(2)}€ ${translations.menu.dosage[objKey]}`}
                                            </span>
                                    })
                            }
                        </div>
                        <div className={classes.vatInfo}>
                            {translations.menu.disclaimers.vat}
                        </div>
                    </div>
                    <div className={classes.foodImage}>
                        <img alt={this.props.name} src={this.props.image} />
                    </div>
                    <div className={classes.foodInfo}>
                        <div className={classes.foodNutrition} onClick={this.tooltipClickHandler.bind(this)}>
                            {tooltipContent}
                            {translations.menu.disclaimers.allergies_and_nutrition} {allergiesContent}
                        </div>
                        <p className={classes.foodDescription}
                            dangerouslySetInnerHTML={{ __html: this.props.description }} />
                    </div>
                    <button onClick={this.props.close}>{translations.menu.buttons.close}</button>
                </div>
            </div>
        );
    }
};

export default FoodItemDetails;