import React from 'react';
import LanguageItem from './LanguageItem/LanguageItem'

import classes from './LanguageItems.module.scss';

const LanguageItems = (props) => {
    const appLayout = localStorage.getItem('app_layout') || 'ementas_digitais';

    return (
        <div className={classes[appLayout]}>
            <div className={classes.LanguageItems}>
                {
                    props.items ? props.items.map(item => (
                        <LanguageItem
                            key={item}
                            lang={item} />
                    )) : null
                }
            </div>
        </div>
    )
};


export default LanguageItems;