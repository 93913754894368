import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeOrderItem, incrementOrderItem, decrementOrderItem } from '../../../redux/actions/order-item';

import Modal from '../../UI/Modal/Modal';
import FoodItemDetails from '../../FoodItems/FoodItem/FoodItemDetails/FoodItemDetails';
import FoodItemDetailsChefHouse from '../../FoodItems/FoodItem/FoodItemDetails/FoodItemDetailsChefHouse';

import classes from './OrderItem.module.scss';

class OrderItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            detailed: false
        }

        this.removeOrderItem = this.removeOrderItem.bind(this);
        this.incrementOrderItem = this.incrementOrderItem.bind(this);
        this.decrementOrderItem = this.decrementOrderItem.bind(this);
        this.detailViewOpenedHandler = this.detailViewOpenedHandler.bind(this);
        this.detailViewClosedHandler = this.detailViewClosedHandler.bind(this);
    }

    detailViewOpenedHandler() {
        this.setState({
            detailed: true
        });
    }

    detailViewClosedHandler() {
        this.setState({
            detailed: false
        });
    }

    incrementOrderItem() {
        this.props.dispatch(incrementOrderItem(this.props.index));
    }

    decrementOrderItem() {
        this.props.dispatch(decrementOrderItem(this.props.index));
    }

    removeOrderItem() {
        this.props.dispatch(removeOrderItem(this.props.index));
    };

    render() {
        const lang = localStorage.getItem('lang') || 'pt';
        const translations = require(`../../../assets/translations/${lang}.json`);
        const appLayout = localStorage.getItem('app_layout') || 'ementas_digitais';

        const modal = this.state.detailed ?
        <Modal show={this.state.detailed} modalClosed={this.detailViewClosedHandler}>
            {
                appLayout === 'chef_house' ?
                    <FoodItemDetailsChefHouse
                        image={this.props.item.image}
                        id={this.props.item.id}
                        name={this.props.item.name}
                        description={this.props.item.description}
                        nutrition={this.props.item.nutrition}
                        allergies={this.props.item.allergies}
                        price={this.props.item.price}
                        otherPrices={this.props.item.otherPrices}
                        close={this.detailViewClosedHandler} /> :
                    <FoodItemDetails
                        image={this.props.item.image}
                        id={this.props.item.id}
                        name={this.props.item.name}
                        description={this.props.item.description}
                        nutrition={this.props.item.nutrition}
                        allergies={this.props.item.allergies}
                        price={this.props.item.price}
                        otherPrices={this.props.item.otherPrices}
                        close={this.detailViewClosedHandler} />
            }
        </Modal> : null;

        return (
            <div className={classes[appLayout]}>
                {modal}
                <div className={classes.OrderItem}>
                    <div className={classes.OrderItemImageWrapper} onClick={this.detailViewOpenedHandler}>
                        <div className={classes.OrderItemImage} style={{ backgroundImage: `url(${this.props.image}` }} />
                    </div>
                    <div className={classes.OrderItemName} onClick={this.detailViewOpenedHandler}>{this.props.name}</div>
                    <div className={classes.orderAmountControls}>
                        <button className={classes.changeOrderAmountBtn} onClick={this.decrementOrderItem}>-</button>
                        <div className={classes.orderAmount}>{this.props.quantity}</div>
                        <button className={classes.changeOrderAmountBtn} onClick={this.incrementOrderItem}>+</button>
                        <div className={this.props.dosage.length > 0 ? classes.orderItemDosage : [classes.orderItemDosage, classes.hidden].join(' ')}>{this.props.dosage}</div>
                        <button onClick={this.removeOrderItem} className={classes.orderItemRemoveBtn}>{`${translations.menu.order.remove} X`}</button>
                    </div>
          
                </div>
            </div>
        );

    }
};

export default connect()(OrderItem);