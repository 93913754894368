import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addOrderItem } from '../../../../redux/actions/order-item';

import classes from './FoodItemDetailsChefHouse.module.scss';

class FoodItemDetailsChefHouse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showTooltip: false,
            selectedAmount: 0,
            selectedDosage: '',
            isDosageSelectorOpen: false
        }

        this.tooltipClickHandler = this.tooltipClickHandler.bind(this);
        this.dosageSelectorClickHandler = this.dosageSelectorClickHandler.bind(this);
        this.incrementAmount = this.incrementAmount.bind(this);
        this.decrementAmount = this.decrementAmount.bind(this);
        this.addToOrder = this.addToOrder.bind(this);
        this.toBoolean = this.toBoolean.bind(this);
    }

    tooltipClickHandler() {
        this.setState({
            showTooltip: !this.state.showTooltip
        });
    }

    dosageSelectorClickHandler() {
        this.setState({
            isDosageSelectorOpen: !this.state.isDosageSelectorOpen
        });
    }

    selectDosageClickHandler(dosage) {
        this.setState({
            selectedDosage: dosage,
            isDosageSelectorOpen: false
        });
    }

    incrementAmount() {
        this.setState({
            selectedAmount: this.state.selectedAmount + 1
        });
    }

    decrementAmount() {
        if (this.state.selectedAmount > 0) {
            this.setState({
                selectedAmount: this.state.selectedAmount - 1
            });
        }
    }

    addToOrder() {
        this.setState({
            selectedAmount: 0,
            dosage: ''
        });

        this.props.dispatch(addOrderItem({
            ...this.props,
            quantity: this.state.selectedAmount,
            dosage: this.state.selectedDosage
        }));

        this.props.close();
    }

    toBoolean(value) {
        return value === 'true';
    }

    render() {
        const appLayout = localStorage.getItem('app_layout') || 'ementas_digitais';
        const lang = localStorage.getItem('lang') || 'pt';
        const translations = require(`../../../../assets/translations/${lang}.json`);
        const hasOrderOptions = this.toBoolean(localStorage.getItem('display_cart'));

        const tooltipContent = this.state.showTooltip ?
            <span className={classes.tooltip}>
                <div className={classes.allergies}>
                    <div className={classes.tooltipTitle}><h2>{translations.menu.allergies.title}</h2></div>
                    {
                        this.props.allergies.length > 0 ? this.props.allergies.map(allergy => {
                            const allergen = allergy.replace('allergies.', '');
                            return <div key={allergy}
                                className={classes.tooltipAllergen}
                                style={{ textAlign: 'left' }}>
                                <span>{translations.menu.allergies[allergen]}</span>
                            </div>
                        }) : <span>{translations.menu.allergies.no_allergens}</span>
                    }
                </div>
                <div className={classes.nutrition}>
                    <div className={classes.tooltipTitle}><h2>{translations.menu.nutrition.title}</h2></div>
                    {
                        Object.keys(this.props.nutrition).length > 0 ?
                            <table className={classes.tooltipNutrition}>
                                <tbody>
                                    <tr>
                                        <td className={[classes.nutritionType, classes.nutritionInfo].join(' ')}>
                                        </td>
                                        <td className={[classes.nutritionValue, classes.nutritionInfo].join(' ')}>
                                            100g
                                </td>
                                    </tr>
                                    {
                                        Object.keys(this.props.nutrition).map(objKey => {
                                            return <tr key={objKey}>
                                                <td className={classes.nutritionType}>{translations.menu.nutrition[objKey]}</td>
                                                <td className={classes.nutritionValue}>{this.props.nutrition[objKey]}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table> : <span>{translations.menu.nutrition.no_nutrition_info}</span>
                    }
                </div>


                <button className={classes.backButton} onClick={() => this.setState({
                    showTooltip: false
                })}>
                    <span>
                        <span className={classes.arrow}>{'\uf177'}</span>
                        <span className={classes.title}>{translations.menu.buttons.back}</span>
                    </span>
                </button>
            </span> : null;

        return (
            <div className={classes[appLayout]}>
                <div className={classes.FoodItemDetails}>
                    <div className={classes.foodImage}>
                        <img alt={this.props.name} src={this.props.image} />
                    </div>
                    <div className={classes.foodMain}>
                        <div className={classes.foodName}>
                            {this.props.name}
                        </div>
                        <div className={classes.foodPrice}>
                            {
                                !this.props.price ? null :
                                    <span>{`€ ${parseFloat(this.props.price).toFixed(2)} ${Object.keys(this.props.otherPrices).length > 0 ? '/' : ''}`}</span>
                            }
                            {
                                Object.keys(this.props.otherPrices).length <= 0 ? null :
                                    Object.keys(this.props.otherPrices).map((objKey, index) => {
                                        const price = this.props.otherPrices[objKey].toString().trim();

                                        return parseFloat(price) <= 0 ? null :
                                            <span key={objKey}>
                                                {`${index > 0 ? '/' : ''} ${parseFloat(price).toFixed(2)}€ ${translations.menu.dosage[objKey]}`}
                                            </span>
                                    })
                            }
                            {
                                !this.props.price ? null :
                                    <span>{`/ ${translations.menu.disclaimers.vat}`}</span>
                            }
                        </div>

                    </div>
                    <div className={classes.foodInfo}>
                        <p className={classes.foodDescription}
                            dangerouslySetInnerHTML={{ __html: this.props.description }} />
                        <div className={classes.foodNutrition} onClick={this.tooltipClickHandler.bind(this)}>
                            {translations.menu.disclaimers.allergies_and_nutrition_ch}
                        </div>
                        {   hasOrderOptions ?
                            <div className={classes.orderControls}>
                                <div className={classes.orderAmountControls}>
                                    <button className={classes.changeOrderAmountBtn} onClick={this.decrementAmount}>-</button>
                                    <div className={classes.orderAmount}>{this.state.selectedAmount}</div>
                                    <button className={classes.changeOrderAmountBtn} onClick={this.incrementAmount}>+</button>
                                </div>
                                {
                                    Object.keys(this.props.otherPrices).length > 0 ?
                                        this.state.isDosageSelectorOpen ?
                                            <div className={classes.changeOrderDosageOpenWrapper}>
                                                <div className={classes.changeOrderDosageOpen}>
                                                    {
                                                        Object.keys(this.props.otherPrices).map(objKey => {
                                                            return (
                                                                <div key={objKey} onClick={() => this.selectDosageClickHandler(translations.menu.order.dosage[objKey])}>
                                                                    {translations.menu.order.dosage[objKey]}
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <span onClick={this.dosageSelectorClickHandler}
                                                        className={classes.changeOrderDosageOpenText}>{translations.menu.order.select_dosage}</span>
                                                    <span className={classes.chevron}></span>
                                                </div>
                                            </div> :
                                            <div className={classes.changeOrderDosageCloseWrapper} onClick={this.dosageSelectorClickHandler}>
                                                <div className={classes.changeOrderDosageClose}>
                                                    {
                                                        this.state.selectedDosage.length <= 0 ?
                                                            <span className={classes.changeOrderDosageCloseText}>{translations.menu.order.select_dosage}</span> :
                                                            <span className={classes.changeOrderDosageCloseText}>{this.state.selectedDosage}</span>
                                                    }
                                                    <span className={[classes.chevron, classes.bottom].join(' ')}></span>
                                                </div>
                                            </div>
                                        : null
                                }

                                <button className={classes.orderBtn} onClick={this.addToOrder}>{translations.menu.order.order}</button>
                            </div> : null
                        }
                    </div>
                    {tooltipContent}
                </div>
            </div>
        );
    }
};

export default connect()(FoodItemDetailsChefHouse);