import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeAllOrderItems } from '../../redux/actions/order-item';
import OrderItem from './OrderItem/OrderItem';

import classes from './OrderItems.module.scss';

class OrderItems extends Component {
    constructor(props) {
        super(props);

        this.removeAllOrderItems = this.removeAllOrderItems.bind(this);
    }

    removeAllOrderItems() {
        this.props.dispatch(removeAllOrderItems());
    };

    render() {
        const appLayout = localStorage.getItem('app_layout') || 'ementas_digitais';

        return (
            <div className={classes[appLayout]}>
                <div className={classes.OrderItems}>
                    {
                        this.props.orderItems.map((orderItem, index) => (
                            <OrderItem 
                                key={index}
                                index={index}
                                name={orderItem.name}
                                image={orderItem.image}
                                dosage={orderItem.dosage}
                                quantity={orderItem.quantity}
                                item={orderItem} />
                        ))
                    }
                </div>
                <div className={classes.OrderItemsRemoveAll} onClick={this.removeAllOrderItems}>
                    <div className={classes.OrderItemsRemoveAllIcon}></div>
                </div>
            </div>
        );

    }
};

const mapStateToProps = state => ({
    orderItems: state.orderItems
});

export default connect(mapStateToProps)(OrderItems);