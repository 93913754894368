import React, {Component, Fragment} from 'react';
import NavigationItem from './NavigationItem/NavigationItem'
import DrinkItems from '../DrinkItems/DrinkItems';

class NavigationItems extends Component {
    state = {
        showDrinks: false,
        selectedId: -1,
        navItem: null,
        drinkItems: []
    }

    navigationItemClickHandler(item) {
        this.props.selected(item);
        this.props.setVisible('food');

        this.setState({
            selectedId: item.id
        });

        if(item.category &&
            item.category.tag === 'drinks' && 
            item.category.children.length > 0) {
            this.setState({
                showDrinks: true,
                navItem: item,
                drinkItems: item.category.children
            });
        }
    }

    drinkItemsCloseHandler() {
        this.setState({
            showDrinks: false,
            navItem: null,
            drinkItems: []
        });
    }

    render() {
        const navigationItems = this.props.items ? this.props.items.map(item => (
            <NavigationItem
                onClick={() => this.navigationItemClickHandler(item)}
                key={item.id}
                isSelected={this.props.visible === 'food' && item.id === this.state.selectedId}
                hasChildren={item.category && item.category.children.length > 0}
                name={item.name}
                icon={item.icon}
                type={item.type}
                isDaily={item.is_daily_config}/>
            )) : null;

            if(this.state.selectedId < 0 && this.props.items && this.props.items.length > 0) {
                this.navigationItemClickHandler(this.props.items[0]);
            }

        return (
            <Fragment> 
                <DrinkItems 
                    show={this.state.showDrinks}
                    items={this.state.drinkItems}
                    close={this.drinkItemsCloseHandler.bind(this)} 
                    selected={this.props.selected} 
                    navItem={this.state.navItem}/>
                <div>
                    {navigationItems} 
                </div>
         </Fragment>
        );
    }
}

export default NavigationItems;