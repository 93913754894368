import React from 'react';

import classes from './WineItem.module.scss';

const WineItem = (props) => {
    const appLayout = localStorage.getItem('app_layout') || 'ementas_digitais';

    const wineBtnClasses = [classes.WineItem, classes[props.tag], props.isSelected ? classes.selected : null].join(' ');
    
    return (
        <div className={classes[appLayout]}>
            <button className={wineBtnClasses} onClick={props.onClick}>
                <span className={classes.title}>
                    {props.name}
                </span>
            </button>
        </div>
    )
}

export default WineItem;