import React from 'react';

import classes from './Backdrop.module.scss';

const backdrop = (props) => {
    const appLayout = localStorage.getItem('app_layout') || 'ementas_digitais';

    return (
        <div className={classes[appLayout]}>
            {
                props.show ? <div className={classes.Backdrop}
                    onClick={props.clicked}></div> : null
            }
        </div>

    )
};

export default backdrop;