import { ADD_ORDER_ITEM, REMOVE_ORDER_ITEM, REMOVE_ALL_ORDER_ITEMS, INC_ORDER_ITEM, DEC_ORDER_ITEM} from '../constants/action-items';

const initialState = [];

const addOrderItem = (state, action) => {
    let updatedState = [...state];
    let itemIndex = -1;

    for(let i = 0; i < updatedState.length; i++) {
        if(updatedState[i].id === action.payload.id && 
            updatedState[i].dosage === action.payload.dosage) {
            itemIndex = i;
            break;
        }
    }

    if(itemIndex < 0) {
        updatedState.push(action.payload);
    } else {
        updatedState[itemIndex].quantity += action.payload.quantity
    }

    return updatedState;
};

const removeOrderItem = (state, action) => {
    let updatedState = [...state];
    updatedState.splice(action.payload, 1);
    return updatedState;
}

const removeAllOrderItems = () => {
    return initialState;
}

const incrementOrderItem = (state, action) => {
    let updatedState = [...state];
    updatedState[action.payload].quantity++;
    return updatedState;
}
const decrementOrderItem = (state, action) => {
    let updatedState = [...state];

    if(updatedState[action.payload].quantity > 0) {
        updatedState[action.payload].quantity--;
    }

    return updatedState;
}

const orderItem = (state = initialState, action) => {
    switch(action.type) {
        case ADD_ORDER_ITEM: return addOrderItem(state, action);
        case REMOVE_ORDER_ITEM: return removeOrderItem(state, action); 
        case REMOVE_ALL_ORDER_ITEMS: return removeAllOrderItems(); 
        case INC_ORDER_ITEM: return incrementOrderItem(state, action);
        case DEC_ORDER_ITEM: return decrementOrderItem(state, action);
        default: return state;
    };
}

export default orderItem;