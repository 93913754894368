import React, { Component } from 'react';

import SakeItem from './SakeItem/SakeItem';

import classes from './SakeItems.module.scss';

class SakeItems extends Component {
    constructor(props) {
        super(props);

        this.state =  {
            selectedTag: null
        }

        this.clickHandler = this.clickHandler.bind(this);
    }

    clickHandler(item) {
        this.props.selected(item);

        this.setState({
            selectedTag: item.tag
        });
    }

    render() {
        const appLayout = localStorage.getItem('app_layout') || 'ementas_digitais';
        const appLayoutConfig = require('../../assets/layout-config.json');
        const currentLayoutConfig = appLayoutConfig[appLayout];
        const lang = localStorage.getItem('lang') || 'pt';
        const translations = require(`../../assets/translations/${lang}.json`);

        let attachedClasses = [classes.SakeItems, classes.Close].join(' ');

        if (this.props.show) {
            attachedClasses = [classes.SakeItems, classes.Open].join(' ');
        }

        return (
            <div className={classes[appLayout]}>
                <div className={attachedClasses}>
                <button 
                        className={classes[`${appLayout}_BackButton`]}
                        onClick={this.props.close}>
                            <span>
                                <span className={classes.arrow}>{'\uf177'}</span>
                                <span className={classes.title}>{translations.menu.buttons.back}</span>
                            </span>
                        </button>
                    {currentLayoutConfig.externalSakeFilter ?
                        window.innerHeight > window.innerWidth ?
                            <div className={classes.SakeFilters}>
                                {
                                    this.props.items.map(item => (
                                        <SakeItem
                                            onClick={() => this.clickHandler(item)}
                                            isSelected={item.tag === this.state.selectedTag}
                                            key={item.id}
                                            name={item.name}
                                            tag={item.tag} />
                                    ))
                                }
                            </div>
                            : null :
                        this.props.items.map(item => (
                            <SakeItem
                                onClick={() => this.props.selected(item)}
                                key={item.id}
                                name={item.name}
                                tag={item.tag} />
                        ))
                    }
                </div>
            </div>
        )
    }
}

export default SakeItems;